import gsap from 'gsap'

const header = {
  init() {
    const headerEl = document.querySelector('#header')

    if (headerEl) {
      const animEls = Array.from(headerEl.querySelectorAll('.js-header-anim'))

      window.addEventListener('load', function(e) {
        gsap.fromTo(animEls, {
          opacity: 0, y: 10
        }, {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.1,
          onComplete: () => {
            gsap.set(animEls, {clearProps: 'all'})
          }
        })
      })
    }
  }
}

export default header
