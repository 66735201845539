const signup = {
  init() {
    const body = document.body
    const signupOpen = document.querySelector('#signup-open');
    const signupClose = document.querySelector('#signup-close');

    if (signupOpen) {
      signupOpen.addEventListener('click', function(e) {
        body.classList.add('signup-is-open')
      })

      signupClose.addEventListener('click', function(e) {
        body.classList.remove('signup-is-open')
      })
    }
  }
}

export default signup
