import gsap from 'gsap'

const itemDelay = 1500

function handler(entries, observer) {
  let itemLoad = 0

	entries.forEach(entry => {
    if (entry.intersectionRatio > 0) {

      const timeout = window.setTimeout(() => {
        entry.target.classList.add('observe-is-visible')
      }, itemLoad/10*itemDelay)

      itemLoad++
    	observer.unobserve(entry.target)
    }
  })
}

const content = {
  init() {
    const intersectionObserver = new IntersectionObserver(handler);

    // get your elements, by class name '.js-item'
    const elements = [...document.querySelectorAll('[class*="js-observe"]')]

    if (elements.length) {
      // start observing your elements
      elements.forEach((element) => intersectionObserver.observe(element))
    }
  }
}

export default content
