require('intersection-observer')

import nav from './modules/nav'
import route from './modules/route'
import signup from './modules/signup'
import content from './modules/content'
import slides from './modules/slides'
import header from './modules/header'

nav.init()
route.init()
signup.init()
content.init()
slides.init()
header.init()

// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);
