import { gsap } from 'gsap'

const route = {
  init() {
    const links = document.querySelectorAll('a:not([target="_blank"])')
    const body = document.body

    if (links.length) {
      links.forEach(link => {
        link.addEventListener('click', function(e) {
          e.preventDefault()
          const href = e.currentTarget.href
          gsap.to(body, {duration: 0.2, opacity: 0, ease: 'none', onComplete: () => {
            window.location = href
          }})
        })
      })
    }

    window.addEventListener('load', (event) => {
      body.classList.remove('body-is-loading')
      body.classList.add('body-is-ready')
    })
  }
}

export default route
