import gsap from 'gsap'

function handler(entries, observer) {

}

const slides = {
  init() {
    const slidesEl = document.querySelector('#slides')

    if (slidesEl) {
      const intersectionObserver = new IntersectionObserver((entries, observer) => {
        const entry = entries[0]

        if (entry.intersectionRatio > 0) {
          const els = Array.from(document.querySelectorAll('.js-slide'))
          els.reverse()

          function crossfade(){
            gsap.to(els[0], {duration: 1, autoAlpha:0})
            gsap.to(els[1], {duration: 1, autoAlpha:1})
            els.push(els.shift())
          }

          const cycle = setInterval(crossfade, 15000)

          observer.unobserve(entry.target)
        }
      })

      intersectionObserver.observe(slidesEl)
    }
  }
}

export default slides
