const nav = {
  init() {
    const btn = document.querySelector('#nav-btn');
    const links = document.querySelectorAll('.js-link');

    if (btn) {
      const body = document.body

      btn.addEventListener('click', function(e) {
        body.classList.toggle('nav-is-active')
      })
    }

    if (links) {
      links.forEach((link) => {
        if (link.closest('.js-dropdown')) {
          const parentDropdown = link.closest('.js-dropdown')

          link.addEventListener("focus", function() {
            parentDropdown.classList.add("focus")
          })
          link.addEventListener("blur", function() {
            parentDropdown.classList.remove("focus")
          })
        }
      })
    }
  }
}

export default nav
